// const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

/**
 * format the received date to be for example 20 JAN 2023, 1:30 PM
 * @param date
 * @returns formatted date string
 */
export function formatCreatedAtDate(date: string | null, locale: 'ar' | 'en'): string | null {
  if (!date) return null;

  const parsedDate = new Date(date).toLocaleDateString(locale ? 'ar-EG' : 'en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  return parsedDate;
}

export function formatDate(date: Date, locale: string) {
  if (!date) return null;

  const parsedDate = new Intl.DateTimeFormat(locale === 'ar' ? 'ar-EG' : 'en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).formatToParts(date);

  const month = parsedDate.find(part => part.type === 'month')?.value.toUpperCase() || '';
  const day = parsedDate.find(part => part.type === 'day')?.value || '';
  const year = parsedDate.find(part => part.type === 'year')?.value || '';

  return `${day} ${month} ${year}`;
}
